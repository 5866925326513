import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const HELMETQUERY = graphql`
  {
    sanityStoreSettings {
      name

      logo {
        asset {
          url
        }
      }
    }
  }
`;

export default function SEO({ children, location, description, title, image }) {
  const { sanityStoreSettings } = useStaticQuery(HELMETQUERY);

  return (
    <Helmet titleTemplate={`%s - ${sanityStoreSettings.name}`}>
      <html lang="en" />
      <title>{title}</title>

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />

      {/* meta tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <meta charSet="UTF-8" />
      <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />

      <meta
        name="description"
        content={description || sanityStoreSettings.name}
      />

      {/* Open Graph */}
      {location && <meta property="og:url" content={location} />}
      <meta
        property="og:image"
        content={image || sanityStoreSettings.logo.asset.url}
      />

      <meta property="og:title" content={title} key="ogtitle" />

      <meta
        proprty="og:site_name"
        content={sanityStoreSettings.name}
        key="ogsitename"
      />

      <meta
        property="og:description"
        content={description || sanityStoreSettings.name}
        key="ogdescription"
      />

      {children}
    </Helmet>
  );
}

SEO.propTypes = {
  children: PropTypes.any,
  location: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
};
